.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    margin: 0;
  }
  
  .close {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1.5rem;
  }
  
  .modal-body {
    margin-top: 10px;
  }
  
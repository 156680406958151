/* General Styles */
/* :root {
  --bg-info-subtle: #d1ecf1; 
}

.dotted-background {
  background-color: var(--bg-info-subtle);
} */


.app-content {
  position: relative;
}

.navbar-container {
  position: sticky;
  top: 0;
  z-index: 2;
  background: inherit;
}



/* Scroll Buttons */
.scroll-buttons {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.scroll-btn {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
}

.scroll-btn:hover {
  background-color: #0056b3;
}

.scroll-top,
.scroll-bottom {
  background-color: #00b7ff;
}
/* General Navbar Styles */
.navbar {
  border-bottom: 1px solid #ddd;
  transition: all 0.3s ease;
}

.bg-dark {
  background-color: #1e2a47 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

.text-primary {
  color: #00aaff !important;
}

.text-white {
  color: #fff !important;
}

/* Transparent Navbar */
.navbar.transparent {
  background-color: transparent !important;
  box-shadow: none;
}

.navbar-toggler {
  border-color: #00aaff;
}

.navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: inherit;
  padding: 10px 15px;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar-nav .nav-item .nav-link.active {
  border-bottom: 2px solid #00aaff;
  font-weight: 700;
}

.navbar-nav .nav-item .btn-primary {
  border-radius: 25px;
  font-weight: 600;
  padding: 8px 20px;
  font-size: 16px;
  border: 2px solid #00aaff;
  background-color: #1976d2;
  color: white;
  transition: all 0.3s ease;
}

.navbar-nav .nav-item .btn-primary:hover {
  background-color: #1565c0;
  border-color: #0288d1;
}

/* Navbar Brand & Logo */
.navbar-brand {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 40px;
  height: auto;
  margin-left: 5px;
}

.navbar-nav {
  font-size: 15px;
  text-align: center;
}

.navbar-collapse {
  margin-top: 10px;
}

/* Mobile Nav */
@media (max-width: 767px) {
  .navbar-nav {
    background-color: #1e2a47;
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }
  .navbar-nav .nav-item {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

/* TutorialBox.css */
.tutorial-container {
    margin-top: 70px;
    padding-bottom: 0;
}

.navbar {
    border-bottom: 2px solid #ddd;
    z-index: 1000;
}

.tech-carousel {
    display: flex;
    overflow-x: hidden;
    transition: transform 0.3s ease;
    padding: 5px 0;
}

.tech-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    transition: background 0.3s ease;
}

.tech-btn:hover {
    background-color: #f0f0f0;
}

.tech-icon {
    font-size: 20px;
}

.sidebar {
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    width: 20%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.list-group-item {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.list-group-item:hover {
    background-color: #e0e0e0;
}

.content {
    min-height: 100vh;
    padding: 20px;
    background-color: #fafafa;
    border-left: 1px solid #ddd;
}

.tutorial-card {
    margin-top: 50px;
}

.section-content {
    margin-top: 20px;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .sidebar {
        width: 25%;
    }

    .content {
        padding-top: 30px;
    }
}

@media (max-width: 992px) {
    .sidebar {
        width: 30%;
    }

    .tech-carousel {
        flex-wrap: wrap;
        justify-content: center;
    }

    .tech-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .tutorial-container {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .tutorial-row {
        flex-direction: column;
    }

    .col-3 {
        width: 100%;
    }

    .sidebar {
        position: relative;
        width: 100%;
        height: auto;
        box-shadow: none;
    }

    .content {
        padding-top: 20px;
        margin-top: 20px;
        border-left: none;
    }

    .navbar-nav {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 576px) {
    .navbar {
        border-bottom: 1px solid #ddd;
    }

    .tech-btn {
        padding: 8px;
    }

    .tech-icon {
        font-size: 18px;
    }

    .tutorial-container {
        margin-top: 30px;
    }

    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
        box-shadow: none;
    }

    .tutorial-card {
        margin-top: 30px;
    }
}

.welcome-message {
    margin-top: 50px;
}

.welcome-message h2 {
    font-size: 2rem;
    margin-bottom: 15px;
}

.welcome-message p {
    font-size: 1.2rem;
    color: #555;
}

/* Default list item styling */
.list-group-item {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect */
.list-group-item:hover {
    background-color: #f0f0f0;
    /* Light gray on hover */
}

/* Active state (when clicked) */
.list-group-item.active {
    background-color: #007bff;
    /* Blue color for clicked item */
    color: white;
    /* White text for active item */
}

/* Optional: Add a hover effect when item is active */
.list-group-item.active:hover {
    background-color: #0056b3;
    /* Darker blue when hovering on the active item */
}

.card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-inner:hover {
    transform: scale(1.05);
    /* Slight zoom */
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
    /* Highlight shadow */
}

.card-inner:hover h5 {
    color: darkblue;
    /* Change text color on hover */
}
.btn-light:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
    background-color: #f9f9f9;
  }
  
  .btn-light:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
  }
  